import React from 'react';
import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import Home from './pages/Home';
import KeyExpertise from './pages/KeyExpertise';
import OurServices from './pages/OurServices';
import ContactUs from './pages/ContactUs';
import Blog from './pages/Blog';

function App() {
  return (
    <div className="App">
      <div className="page-container">
          <div className="wrapper">
            <Header />
            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/key-expertise' component={KeyExpertise} />
              <Route path='/our-services' component={OurServices} />
              <Route path='/contact-us' component={ContactUs} />
              <Route path='/blog' component={Blog} />
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;

import React from 'react';

import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'; 

import './Footer.css';

const Footer = () => {
    return (
        <footer id="footer">
            <Container>
                <Row>
                    <Col sm={6}>
                        <ul>
                            <li><Link className="footerLinks" to="/key-expertise">Key Expertise</Link></li>
                            <li><Link className="footerLinks" to="/our-services">Our Services</Link></li>
                            <li><Link className="footerLinks" to="/contact-us">Contact us</Link></li>
                        </ul>
                    </Col>
                    <Col sm={6}>
                        <p id="footerContacts">
                            info@gostack.eu <br />
                            Rīga, Spilves iela 16, LV-1055 <br />
                            +371 26756748
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p id="copywrite">©2022 GOStack.eu, all rights reserved</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
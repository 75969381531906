import React, { useEffect } from "react";
import { Col, Row, Container, Image } from 'react-bootstrap';
import { gsap } from "gsap";

import './pages.css';


const Home = () => {    
    useEffect(() => { 
        gsap.from(".sec-content", { opacity: 0, duration:1 },"+=0");        
        gsap.from(".fpImgSecondary", { scale: 0, duration:1,ease: "bounce.out" },"+=0");        
    });

    return (
        <div>
            <Container>
                <Row id="landingSection">
                    <Col sm={12} md={6}>
                        <h1 id="frontPageHeader">Build stuff that</h1>
                        <h1 id="header">matters</h1>
                        <h4 id="fpQuote">GOStack provides the leading enterprise DevOps solutions.</h4>
                        <h6 id="fpQuoteSecondary-desc">To help our clients build billion-dollar businesses we enable developers to focus on delivering great software, while building and implementing world-class infrastructures that excites and satisfies your business needs.</h6>
                        {/* <span><Link id="linkToServices" to="our-services">See our services</Link></span> */}
                    </Col>
                    <Col sm={12} md={6}>
                        <Image id="fpImgMain" alt="main" src={require('../public/img/fp-main.jpeg')} fluid></Image>
                    </Col>
                </Row>
                
                <Row className="fpSections">
                    <Col sm={12}>
                        <h3 id="fpQuoteSecondary">You develop great software, we'll take care of the rest!</h3>
                        {/* <h6 id="fpQuoteSecondary-desc">To help our clients build billion-dollar businesses we enable developers to focus on delivering great software, while building and implementing world-class infrastructures that excites and satisfies your business needs.</h6> */}
                    </Col>
                </Row>

                <Row className="fpSections">
                    <Col sm={12} md={6} className="order-2 order-md-1 sec-content">
                        <h1 className="fpSecondaryHeader">Who are we?</h1>
                        <p className="secondaryTexts">We’re ambitious remote DevOps team with cloud architects and big data engineers onboard. With help of technology we are delivering your business goals efficiently and fast.</p>
                    </Col>
                    <Col sm={12} md={6} className="order-1 order-md-2">
                        <Image className="fpImgSecondary" src={require('../public/img/fp-secondary.png')} fluid></Image>
                    </Col>
                </Row>

                <br />                

                <Row className="fpSections">
                    <Col sm={12} md={6} className="order-1">
                        <Image fluid className="fpImgSecondary" src={require('../public/img/main-page-gif.gif')}></Image>
                    </Col>
                    <Col sm={12} md={6} className="order-2 sec-content">
                        <h1 className="fpSecondaryHeader">What are we doing?</h1>
                        <p className="secondaryTexts">We provide support and maintenance to all your IT Infrastructure and services. We know that not everyone has the time to manage hostings, domains, or maintain a system or website, for this reason we offer a support and maintenance service that covers that and so much more.</p>    
                    </Col>
                </Row>

                <Row className="fpSections">
                    <Col sm={12} md={6} className="order-2 order-md-1 sec-content">
                        <h1 className="fpSecondaryHeader">Why GOStack?</h1>
                        <p className="secondaryTexts">No matter what industry you are in, your business can benefit from custom-made technology solutions that enable growth, innovation and efficiency.  We just love what we are doing! Our business, engineering, DevOPs, and software development expertise allows us to bring never seen efficiencies through creating solutions that scales your businesses faster and faster.</p>
                    </Col>
                    <Col sm={12} md={6} className="order-1 order-md-2">
                        <Image fluid className="fpImgSecondary" src={require('../public/img/why-gostack.jpg')}></Image>
                    </Col>
                </Row>

                <Row className="fpSections">
                    <Col>
                        <h1 className="fpSecondaryHeader text-center">Our partners</h1>
                        <Row>
                            <Col md={6}>
                                <Row>
                                    <Col md={4}>
                                        <Image fluid className="clientImg" src={require('../public/img/logo_yellow.png')}></Image>
                                    </Col>
                                    <Col md={8} className="text-right">
                                        <h2 className="clientName">01Event</h2>
                                        <p className="secondaryTexts">Software development<br/>and event management company.</p>
                                        <div className="webLinkContainer">
                                            <a id="clientWebLink" href="https://www.01event.org">Visit their website</a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row>                                    
                                    <Col md={8}>
                                        <h2 className="clientName">ND6 Tech</h2>
                                        <p className="secondaryTexts">Digital Development<br/>and Consulting agency.</p>
                                        <div className="webLinkContainer">
                                            <a id="clientWebLink" href="https://nd6tech.com/">Visit their website</a>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <Image fluid className="clientImg" src={require('../public/img/logo-hi-res.png')}></Image>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>                        
                    </Col>                    
                </Row>
            </Container>
        </div>
    )
}

export default Home;
import React from 'react';

import { Container, Row, Col, Image } from 'react-bootstrap'

import { services } from '../public/media/servicesTexts';

const ourServices = () => {
    return (
        <div>
            <Container>
                <h1 className="sectionHeaders">Our services</h1>
                <hr /> 
                <p className="sectionSummary">Our services covers cloud automation, CI/CD, container orchestration, observability, microservices and serverless architecture support. As a result you get more efficient, can concentrate more on your business which results in faster growth because we are taking care for the rest!</p>
                
                    <div class="items">
                        <Row>
                            {services.map((service, index) => (
                            <Col sm={6} md={6}>
                                <div key={index}>
                                    <div>
                                    <Image className="serviceImages d-flex align-items-center" src={service.imgUrl} alt={service.altText} fluid/>
                                    </div>
                                    <h5 className="serviceName">{service.serviceName}</h5>
                                    <p className="serviceDescription">{service.text}</p>
                                </div>
                            </Col>
                            ))}
                        </Row>
                        
                    </div>
            </Container>
        </div>
    )
}

export default ourServices;
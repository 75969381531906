export const services = [
    {
        "serviceName": "Cloud migrations",
        "imgUrl": require("../img/services/cloud-migration.jpg"),
        "altText": "picture",
        "text": "Trust your Infrastructure migration to experienced and certified professionals! We have done a lot migrations where we have gained valuable experience and faced thousands of challenges. This allowed us to adjust our approaches and develop strategy to flawlessly switchover without a worry. We are following the official “Well-Architected Framework” and provide all of our reference architectures for free!"
    },
    {
        "serviceName": "Cost savings",
        "imgUrl": require("../img/services/cost-optimization.jpg"),
        "altText": "picture",
        "text": "We are successfully utilising our expertise to reduce AWS bills and make our customers happier. It’s a combination of our solutions, DevOps mindset and best practices to increase ability understand costs and with automation and expertise achieve efficiency. You have nothing to lose, as you only pay if we achieve significant savings on your monthly AWS bill!"
    },
    {
        "serviceName": "Continious delivery [CI/CD]",
        "imgUrl": require("../img/services/ci-cd.png"),
        "altText": "picture",
        "text": "Continuous integration and deployment ensure that your software can be reliably released at any time without downtime to multiple environments."
    },
    {
        "serviceName": "Development environments",
        "imgUrl": require("../img/services/devenv3.png"),
        "altText": "picture",
        "text": "We’ll automate your entire local development environment. Software development process will become efficient, repeatable and will rapidly speed up whole lifecycle."
    },
    {
        "serviceName": "Site reliability engineering",
        "imgUrl": require("../img/services/site-reliability.png"),
        "altText": "picture",
        "text": "We'll deliver total visibility into performance, trends, and events what is going on at your infrastructure, enable meaningful alerts that can be escalate to the engineers only when it matters. Develop KPI's and meet your SLA's!"
    },
    {
        "serviceName": "Training and Support",
        "imgUrl": require("../img/services/training-and-support.png"),
        "altText": "picture",
        "text": "Upskilling is the key factor. We are happy to share our expertise. If you have engineers integrated into your team we offer dedicated training and coaching that builds your in-house capabilities."
    },
    {
        "serviceName": "Security and compliance",
        "imgUrl": require("../img/services/security.png"),
        "altText": "picture",
        "text": "You will gain the control and confidence you need to securely run your business with the most flexible and secure cloud computing environment available today. We will take care of it - keep build your business!"
    },
    {
        "serviceName": "Data engineering",
        "imgUrl": require("../img/services/dataengineering.jpeg"),
        "altText": "picture",
        "text": "Data is the key point of every business today. Our Data Engineering Services transform organisational knowledge into insights for informed and timely business decisions. We enable answers to data scientists and business analysts from your data."
    }
]
import React from 'react';

import { Container, Image, Row, Col } from 'react-bootstrap';

import './pages.css';

const blog = () => {
    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <h1 className="sectionHeaders">Blog</h1>
                        <hr />
                        <Image src={require('../public/img/blog.jpg')} fluid></Image>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default blog;
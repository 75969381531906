export const technologies = [
    {
        "techName": "Kubernetes",
        "imgUrl": require("../img/technologies/kubernetes.png"),
        "altText": "kubernetes-image",
        "text": "Kubernetes is one of the most widely-used container orchestration tools on the market. It is used by companies of all sizes. Automate your application deployment , manage a pool of resources and efficiently schedule containers across the cluster. Handle complex operations such as scaling, deployments, rolling updates, rollbacks, high-availability and service discovery."
    },
    {
        "techName": "HELM",
        "imgUrl": require("../img/technologies/helm.png"),
        "altText": "helm-image",
        "text": "Package manager for Kubernetes applications which helps you define, install and upgrade Kubernetes apps."
    },
    {
        "techName": "AWS",
        "imgUrl": require("../img/technologies/aws.png"),
        "altText": "picture",
        "text": "Amazon Web Services (AWS) is a giant that enables companies across the globe to develop their digital infrastructure entirely or partially using the cloud. AWS offers Infrastructure as a service coming with extensive range of IaaS and PaaS services. These include Elastic Cloud Compute (EC2), Managed Kubernetes (EKS), Simple Storage Service (S3), Relational Database Service (RDS) and many other."
    },
    {
        "techName": "Kong",
        "imgUrl": require("../img/technologies/kong.png"),
        "altText": "picture",
        "text": "World's most popular open source microservice API gateway to secure, manage and orchestrate microservice APIs."
    },
    {
        "techName": "PostgreSQL",
        "imgUrl": require("../img/technologies/postgresql.png"),
        "altText": "picture",
        "text": "PostgreSQL is an advanced, enterprise-class, and open-source relational database system which supports both SQL (relational) and JSON (non-relational) querying and is highly stable database backed by more than 20 years of development by the open-source community.Used as the primary data store or data warehouse for many web applications as well as mobile and analytics applications."
    },
    {
        "techName": "Terraform",
        "imgUrl": require("../img/technologies/terraform.png"),
        "altText": "picture",
        "text": "Terraform is an open source tool built by Hashicorp to automate the provisioning of infrastructure resources. It is used to build, manage, update and delete infrastructure resources like virtual machines, containers, networking and others using infrastructure as a coding philosophy. Terraform is a vendor agnostic tool and can manage infrastructure for multiple resource providers (for example AWS, Google Cloud, Azure, Aviatrix, Heroku, Oracle, etc.)."
    },
    {
        "techName": "Ansible",
        "imgUrl": require("../img/technologies/ansible.png"),
        "altText": "picture",
        "text": "Open-source software provisioning, configuration management, and application-deployment tool enabling infrastructure as code."
    },
    {
        "techName": "Prometheus + Grafana",
        "imgUrl": require("../img/technologies/prometheus-grafana.png"),
        "altText": "picture",
        "text": "Monitoring stack used by DevOps teams for storing and visualizing time series data. Prometheus acts as the storage backend and Grafana as the interface for analysis and visualization."
    },
    {
        "techName": "Docker",
        "imgUrl": require("../img/technologies/docker.png"),
        "altText": "picture",
        "text": "Tool designed to make it easier to create, deploy, and run applications by using containers. Containers allow a developer to package up an application with all of the parts it needs, such as libraries and other dependencies, and deploy it as one package."
    }
]
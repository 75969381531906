import React, { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import {technologies} from '../public/media/technologiesTexts';
import { gsap } from "gsap";

const KeyExpertise = () => {
    useEffect(() => {
        gsap.from("#aws-certified", { scale: 0, duration:1,ease: "bounce.out"},"+=0");        
    });
    return (
        <div>
            <Container>
                <h1 className="sectionHeaders text-right">
                    <div className="text-center">Key expertise</div>
                    <Image id="aws-certified" src={require('../public/img/amazon-certified-logo.png')} fluid></Image>
                </h1>
                <hr />
                <p className="sectionSummary">Our team is always eager to put our expertise helping you leverage the full power of the most innovative technology. As we have been building world class infrastructures for a while and touching many tools, trying multiple approaches and various technology stacks we developed the knowledge and expertise needed to assist our customers with successful cloud adoption. We have skills and resources to consult and develop varies Cloud solutions to improve your business output for every size of business.</p>
                    
                <Row>
                    {technologies.map((technology, index) => (
                    <Col md={4} sm={6} xs={12} className="column">
                        <div className="textTemplate" key={index}>
                            <Image className="images" src={technology.imgUrl} alt={technology.altText} fluid></Image>
                            <div className="image__overlay image__overlay--primary">
                                <h5 className="techName">{technology.techName}</h5>
                                <p className="techDescription">{technology.text}</p>
                            </div>
                        </div>
                    </Col>
                    ))}
                </Row>                    
            </Container>
        </div>
    )
}

export default KeyExpertise;
import React from 'react';

import { Navbar, Nav, NavDropdown } from 'react-bootstrap'; 
import { Link } from 'react-router-dom';

import './Header.css';

const Header = () => {
    return (
        <div>
            <Navbar expand="lg">
                <Navbar.Brand href="/"><img id="logo" alt="Logo" src={require("../../public/img/logo.png")}></img></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav><Link className="links" to="/">Home</Link></Nav>
                        <Nav><Link className="links" to="/key-expertise">Key Expertise</Link></Nav>
                        <Nav><Link className="links" to="/our-services">Our Services</Link></Nav>
                        <Nav><Link className="links" to="/contact-us">Contact</Link></Nav>
                        <NavDropdown className="links" title="Resources" id="basic-nav-dropdown">
                            <Nav><Link className="links" to={{ pathname: "https://gostack-blog.weebly.com/" }} target="_blank">Blog</Link></Nav>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default Header;
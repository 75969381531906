import React from 'react';

import { Container, Row, Col, Image } from 'react-bootstrap';

const contactUs = () => {
    return (
        <div>
            <Container>
                <h1 className="sectionHeaders">Get in touch with us</h1>
                <hr />
                <Row className="contacts">
                    <Col sm={6}>                        
                        <Image id="contactGif" fluid src={require('../public/img/contact.gif')}></Image>
                    </Col>
                    <Col sm={6}>
                        <div className="mt-3">
                            <span className="contactsHighlight">SIA GOStack</span>
                        </div>
                        <div className="">
                            <span className="contactsHighlight">E-mail: </span>
                            <a href="mailto:info@gostack.eu">info@gostack.eu</a>
                        </div>
                        <div className="">
                            <span className="contactsHighlight">Address: </span>Rīga, Spilves iela 16, LV-1055
                        </div>
                        <div className="">
                            <span className="contactsHighlight">Phone: </span> +371 26756748
                        </div>                    
                        <div className="mt-3">
                            <span className="contactsHighlight">Requisites</span>
                        </div>
                        <div className="">
                            <span className="contactsHighlight">Reg no: </span>40203215816
                        </div>
                        <div className="">
                            <span className="contactsHighlight">VAT no: </span>LV40203215816
                        </div>
                        <div className="">
                            <span className="contactsHighlight">Bank: </span>Citadele
                        </div>
                        <div className="">
                            <span className="contactsHighlight">BIC/SWIFT: </span>PARXLV22
                        </div>
                        <div className="">
                            <span className="contactsHighlight">Account no: </span>LV05PARX0022603800002
                        </div>                        
                    </Col>                   
                </Row>
            </Container>
        </div>
    )
}

export default contactUs;